<template>
	<div class="bl-card shadow" :class="{opened: opened}" ref="container">
		<BlChatRoom :roomId="roomId">
			<template #header="{room: room, unreadMessages: unreadMessages}">
				<div class="header" @click="toggle()" :class="{unread: unreadMessages}">
					<i class="material-icons legend">chat_bubble</i>
					<h5>{{ room.name }}</h5>
					<i class="bl-icon-button" style="color: var(--bl-legend); font-size: 20px;" v-if="!room.userroom" @click="roomSettings($event)">settings</i>
					<i class="bl-icon-button" @click="controls.close(false)">close</i>
				</div>
			</template>
		</BlChatRoom>
	</div>
</template>

<script>
import { ChatManager } from 'ChatBundle'
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlChatRoomWindowContainer',
	props: ['controls', 'roomId'],
	data() {
		return {
			opened: true,
			managerSubscriptions: {}
		}
	},
	created() {
		this.managerSubscriptions.focus = ChatManager.focus.subscribe(roomId => {
			if(roomId == this.roomId) {
				if(this.opened) this.focus()
				else this.toggle()
			}
		})
	},
	unmounted() {
		this.managerSubscriptions.focus.unsubscribe()
	},
	methods: {
		toggle() {
			this.opened = !this.opened
			if(this.opened) this.$nextTick(() => this.focus())
		},
		focus() {
			this.$refs.container.querySelector('.messageTextContainer > div').focus()
		},
		roomSettings(e) {
			e.stopPropagation()
			Dialog.custom({
				component: 'InternalsChatGroupRoomForm',
				componentProps: {roomId: this.roomId}
			})
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-card {
		width: 350px;
		height: 500px;
		padding: 0;
		overflow: hidden;
		border-bottom: 0;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}

	.header {
		display: flex;
		align-items: center;
		padding-left: 10px;
		cursor: pointer;
		background-color: var(--bl-surface);
		border-bottom: 1px solid var(--bl-border);

		i.legend {
			color: var(--bl-legend);
			font-size: 18px;
			margin-right: 6px;
		}

		h5 {
			font-family: 'Product sans';
			font-size: 14px;
			flex: 1;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.header.unread {
		animation: unreadHeader 2s infinite;
	}

	@keyframes unreadHeader {
		35% {
			background-color: var(--bl-surface);
		}

		36%, 64% {
			background-color: var(--bl-secondary);
		}

		65% {
			background-color: var(--bl-surface);
		}
	}

	.bl-card:not(.opened) {
		height: 48px;
		width: 300px;

		.header {
			border-bottom: 0;
		}
	}
</style>