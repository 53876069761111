<template>
	<div v-bl-input>
		<input autoCapitalize="none" type="text" v-model="value" @input="setFilter()" v-blMobileInput />
		<span class="suffix material-icons" @click="value = null; setFilter();" style="display: block; margin-top: 10px;">{{ value ? 'remove_circle_outline' : '' }}</span>
	</div>
</template>

<script>
export default {
	name: 'BlListFilterSearchString',
	props: ['initialValue', 'metadata'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: null
		}
	},
	created() {
		this.value = this.initialValue
	},
	methods: {
		setFilter() {
			if(this.value) {
				let filters = this.value.split(';').map(s => [this.metadata.field.name, '*=*', s])
				this.$emit('blListFilterChange', [['|'].concat(filters), this.value])
			}
			else this.$emit('blListFilterChange', null)
		}
	}
}
</script>

<style scoped lang="scss">
	.suffix {
		cursor: pointer;
	}
</style>