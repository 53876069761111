import { ViewLoader } from './../services/ViewLoader.js'

export default {
	install: (app, options) => {
		app.config.compilerOptions.isCustomElement = (tag) => tag == 'icon'

		app.config.globalProperties.$t = (key, params = {}) => {
			if(!ViewLoader.data) return ''
			let result = ViewLoader.data.i18n.translations[key] ? ViewLoader.data.i18n.translations[key] : key
			for(let paramKey in params) {
				result = result.replaceAll(new RegExp('{ ?' + paramKey + ' ?}', 'g'), params[paramKey] ? params[paramKey] : '')
			}
			return result
		}
		window.$t = app.config.globalProperties.$t//Bind to directive

		app.provide('i18n', options)
	}
}