<template>
	<BlButton v-if="show" class="outlined primary" :label="label ? label : 'Add'" @click="add()" />
</template>

<script>
export default {
	name: 'BlCollectionAdd',
	props: ['name', 'label', 'default'],
	data() {
		return {
			show: false
		}
	},
	inject: ['blForm', 'blFormReady', 'blFormLiveReloaded'],
	created() {
		if(this.blFormReady === true) this.setShow()
		else this.blFormReady.once(() => this.setShow())
		this.liveReloadSub = this.blFormLiveReloaded.subscribe(() => this.setShow())
	},
	unmounted() {
		this.liveReloadSub.unsubscribe()
	},
	methods: {
		/**
		 * Add value, handles sub sub forms
		 */
		add() {
			const field = this.blForm.getChild(this.name)
			const lastLineAutoadded = field.autoAdd
			const lastLine = field.value.length ? field.value[field.value.length - 1].key : null
			let item = field.addValue()
			if(this.default) {
				for(let key in this.default) item.getChild(key).setValue(this.default[key], false)
					//If last line autoadded, move addLine to previous position
					if(lastLineAutoadded == lastLine) {
						const tmp = field.value[field.value.length - 2]
						field.value[field.value.length - 2] = field.value[field.value.length - 1]
						field.value[field.value.length - 1] = tmp
					}
			}
		},
		setShow() {
			const child = this.blForm.getChild(this.name)
			this.show = child && child.options.show !== false ? true : false
		}
	}
}
</script>

<style scoped>
</style>