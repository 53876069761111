<template>
	<div class="level1 test" :class="{opened: activeItem, mobile: mobile}">
		<div class="toolbar" v-if="!mobile">
			<div v-for="(shortcut, shortcutName) in shortcuts" :key="shortcutName" @click="shortcut.view ? select(shortcut) : null" v-bl-tooltip="shortcut.label" v-link="shortcut.view ? null : shortcut.route" :class="{animate: shortcut.animate}">
				<icon>
					<div class="overlay"></div>
					{{ shortcut.icon }}
				</icon>
				<div class="counter" v-if="shortcut.count">{{ shortcut.count }}</div>
			</div>
		</div>
		<hr v-if="!mobile" />
		<ul class="bl-light-scroll">
			<template v-for="item in structure" :key="item.label">
				<component v-if="item.iconView" :is="item.iconView"></component>
				<li v-else v-link="item.link" @click="item.link ? deselect() : select(item)" :class="{active: activeItem == item}" :style="{display: item.show ? null : 'none'}">
					<div class="overlay"></div>
					<icon>{{ item.icon }}</icon>
					<span>{{ $t(item.label) }}</span>
				</li>
			</template>
		</ul>
	</div>
	<div class="appMainMenu level2 bl-light-scroll" v-if="activeItem" :class="{large: activeItem.large, mobile: mobile}">
		<h1 v-if="activeItem.label && !activeItem.large" :style="{'--bundle-color': '#' + activeItem.color}">
			<icon class="fill">{{ activeItem.icon }}</icon>
			<span>{{ $t(activeItem.label) }}</span>
		</h1>
		<component v-if="activeItem.view" :is="activeItem.view"></component>
	</div>
</template>

<script>
import { Realtime, Variables, Router, ViewLoader, Colors, ViewServices } from 'InterfaceBundle'

export default {
	name: 'InterfaceMainMenu',
	provide() {
		return {
			blMainMenuItems: () => this.activeItem
		}
	},
	methods: {
		select(item) {
			if(this.mobile) {
				this.activeItem = item
				localStorage.setItem('Interface.activeMenu', item.label)
			}
			else {
				if(this.activeItem == item) localStorage.removeItem('Interface.activeMenu')
				else localStorage.setItem('Interface.activeMenu', item.label)
				this.activeItem = this.activeItem == item ? null : item
			}
		},
		deselect() {
			this.activeItem = null
		}
	},
	data() {
		return {
			activeItem: null,
			structure: ViewServices.interfaceData.mainMenu,
			shortcuts: ViewServices.interfaceData.shortcuts,
			rt: {},
			mobile: Variables.mobile
		}
	},
	created() {
		this.loaderReadyUpdate = ViewLoader.ready.subscribe(() => this.$forceUpdate())
		for(let part of this.structure) {
			if(part.label) part.color = Colors.get('m' + part.label)
			if(part.items) {
				part.show = false
				for(let items of Object.values(part.items)) {
					for(let item of items) {
						if(item.link && Router.canAccess(item.link[0])) {
							part.show = true
							break
						}
					}
				}
			}
			else part.show = part.link ? Router.canAccess(part.link) : true
		}

		if(localStorage.getItem('Interface.activeMenu')) {
			let autoSelect = this.structure.filter(i => i.label == localStorage.getItem('Interface.activeMenu'))
			if(autoSelect.length) this.select(autoSelect[0])
		}
		if(!this.mobile) {
			for(let item of Object.keys(this.shortcuts)) {
				this.rt[item] = Realtime.listen('interface.' + item + 'count')
				this.rt[item].then(resp => {
					if(resp > this.shortcuts[item].count) {
						this.shortcuts[item].animate = true
						setTimeout(() => this.shortcuts[item].animate = false, 400)
					}
					this.shortcuts[item].count = resp
				})
			}
		}
	},
	unmounted() {
		if(this.loaderReadyUpdate) this.loaderReadyUpdate.unsubscribe()
		for(let key of Object.keys(this.rt)) this.rt[key].unsubscribe()
	}
}
</script>

<style scoped lang="scss">
.level1 {
	display: flex;
	flex-direction: column;
	background-color: var(--bl-primary);
	height: 100%;
	width: 100px;
	min-width: 100px;
	z-index: 2;
	border-radius: var(--bl-border-radius);
	margin: 5px 0px 5px 5px;
	height: calc(100% - 10px);
	transition: border-top-right-radius .2s, border-bottom-right-radius .2s;

	hr {
		border: none;
		border-top: 1px solid var(--bl-on-primary);
		width: 100%;
		margin: 0;
		height: 0;
		opacity: .4;
	}

	.overlay {
		border-radius: var(--bl-border-radius);
		width: 100%;
		margin-top:  -5px;
		margin-left:  -5px;
		height: 100%;
		position: absolute;
		background-color: var(--bl-on-primary);
		opacity: 0;
		transition: opacity .2s;
	}

	.toolbar {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		padding-bottom: 1px;

		.animate {
			> icon {
				animation: bl-interface-menu-notification-new .4s linear;
			}

			.counter {
				animation: bl-interface-menu-notification-newcounter .4s linear;
			}
		}

		icon {
			padding: 10px 5px;
			cursor: pointer;
			border-radius: var(--bl-border-radius);
			color: var(--bl-on-primary);

			.overlay {
				width: 35px;
				height: 35px;
			}
		}

		icon:hover .overlay {
			opacity: .2;
		}

		.counter {
			pointer-events: none;
			background-color: var(--bl-error);
			color: var(--bl-on-primary);
			font-weight: bold;
			font-size: 13px;
			border-radius: var(--bl-border-radius);
			padding: 2px 4px;
			position: absolute;
			margin-top: -36px;
			margin-left: 17px;
		}
	}

	ul {
		overflow-y: auto;
		overflow-x: hidden;
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			border-radius: var(--bl-border-radius);
			text-align: center;
			color:  var(--bl-on-primary);
			margin: 10px;
			width: 70px;
			height: 70px;
			padding: 5px;
			cursor: pointer;
			position: relative;

			icon {
				display: block;
				font-size: 30px;
				margin-bottom: 5px;
				margin-top: 10px;
			}

			span {
				font-size: 12px;
				width: 100%;
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-family: 'Product sans';
			}
		}

		li.active::after {
			content: '';
			position: absolute;
			left: 83px;
			top: 34px;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-right: 7px solid var(--bl-surface);
			animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		}

		li:hover .overlay {
			opacity: .2;
		}
	}
}

@keyframes bl-interface-menu-notification-new {
	0% {
		transform: rotate(-10deg);
	}
	30% {
		transform: rotate(10deg);
	}
	60% {
		transform: rotate(-6deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes bl-interface-menu-notification-newcounter {
	0% {
		transform: scale(1);
	}
	40% {
		transform: scale(1.1);
	}
	70% {
		transform: scale(1);
	}
}

.level1.opened {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.level2 {
	border-top-right-radius: var(--bl-border-radius);
	border-bottom-right-radius: var(--bl-border-radius);
	animation: slide-right .2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	width: 235px;
	padding: 10px;
	background-color: var(--bl-surface);
	border: 1px solid var(--bl-border);
	border-left: 0;
	margin: 5px 0 5px 0;
	overflow-y: auto;
	overflow-x: hidden;
	transition: width .2s;
}

.level2.large {
	width: 425px;
}

@keyframes slide-left {
	0% {
		transform: translateX(50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes slide-right {
	0% {
		transform: translateX(-50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.level1.mobile {
	border-radius: 0;
	margin: 0;
	height: calc(100% + 5px);
}

.level1.mobile.test {
	margin-top: -1px;
	margin-left: -1px;
}

.level2.mobile {
	margin: 0;
	border-radius: 0;
	border: 0;
	height: calc(100% - 15px);
	width: 100%;
	animation: none;
}

.appMainMenu.level2.mobile :deep .action {
	padding: 8px 8px 6px 8px;

	icon, i.material-icon {
		font-size: 20px;
		margin-top: -2px;
	}

	span {
		font-size: 17px;
	}
}

.level1.test {
	background-color: var(--bl-surface);
	outline: 1px solid var(--bl-border);
	outline-offset: -1px;

	.toolbar {
		icon {
			color: var(--bl-legend);
		}

		icon:hover .overlay {
			opacity: .1;
			outline: 1px solid var(--bl-border);
		}
	}

	hr {
		border-color: var(--bl-border);
		opacity: 1;
	}

	.overlay {
		background-color: var(--bl-primary);
	}

	ul {
		li {
			color: var(--bl-on-surface);

			icon {
				color: var(--bl-legend);
			}
		}

		li:hover .overlay {
			opacity: .1;
			outline: 1px solid var(--bl-border);
		}

		li.active {
			background-color: color-mix(in srgb, var(--bl-primary) 10%, var(--bl-surface));
		}

		li.active::after {
			border-right: 7px solid var(--bl-border);
		}

		li.active::before {
			content: '';
			z-index: 1;
			position: absolute;
			left: 84px;
			top: 35px;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-right: 6px solid var(--bl-surface);
			animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		}
	}
}
</style>