<template>
	<button :disabled="loading || disabled" :type="type" :class="classlist" :style="{backgroundColor: color}" :loading="loading">
		<icon v-if="icon">{{ icon }}</icon>
		<span v-if="label">{{ label }}</span>
		<span v-if="!label && !icon">Button</span>
		<div class="ripple"></div>

		<svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" v-if="loading">
			<circle cx="50" cy="50" r="30" stroke="var(--bl-surface)" stroke-width="10" fill="none"></circle>
			<circle cx="50" cy="50" r="30" stroke="var(--bl-primary)" stroke-width="10" fill="none" transform="rotate(144 50 50)">
				<animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
				<animate attributeName="stroke-dasharray" calcMode="linear" values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882" keyTimes="0;0.5;1" dur="1" begin="0s" repeatCount="indefinite"></animate>
			</circle>
		</svg>
	</button>
</template>

<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlButton',
	props: {
		label: {
			type: String
		},
		icon: {
			type: String
		},
		loading: {
			type: Boolean
		},
		disabled: {
			type: Boolean
		},
		type: {
			type: String,
			default: 'button'
		},
		classlist: {
			type: String
		},
		color: {
			type: String
		},
		triggerClickCtrlS: {
			type: Boolean
		}
	},
	methods: {
		handleKeyboardShortcuts(event) {
			if(event.key == 's' && event.ctrlKey && !this.disabled && !Dialog.getCurrentDialog() && !this.loading && this.triggerClickCtrlS) {
				this.$el.click()
				event.preventDefault()
				event.stopPropagation()
				return false
			}
		}
	},
	created() {
		document.addEventListener('keydown', this.handleKeyboardShortcuts)
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
	},
}
</script>

<style scoped lang="scss">
button {
	position: relative;
	background-color: var(--bl-secondary);
	color: var(--bl-on-secondary);
	font-family: 'Product sans';
	border: 0;
	border-radius: var(--bl-border-radius);
	padding: 0 14px;
	font-size: 15px;
	font-weight: 600;
	height: 36px;
	min-height: 36px;
	overflow: hidden;
	cursor: pointer;
	outline: none;
	letter-spacing: 1px;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: box-shadow .2s cubic-bezier(0.4,0.0,0.2,1), color .2s cubic-bezier(0.4,0.0,0.2,1), padding-right .2s, background-color .2s;

	> span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.ripple {
		transition: box-shadow .2s cubic-bezier(0.4,0.0,0.2,1), opacity .2s cubic-bezier(0.4,0.0,0.2,1);
		position: absolute;
		width: 100%;
		border-radius: var(--bl-border-radius);
		height: 100%;
		opacity: 0;
		z-index: 1;
		top: 0;
		left: 0;
		background-color: var(--bl-background);
	}

	icon {
		font-size: 18px;
		float: left;
		margin: 0 -4px;
	}
}

button[loading="true"] {
	padding-right: 40px;
}

button.primary {
	background-color: var(--bl-primary);
	color: var(--bl-on-primary);
}

button::first-letter {
	text-transform: uppercase;
}

button:disabled {
	cursor: default;
	background-color: var(--bl-border);
}

button:not(:disabled):hover .ripple {
	opacity: .1;
	box-shadow: 0 2px 1px -1px var(--bl-secondary), 0 1px 1px 0 var(--bl-secondary), 0 1px 3px 0 var(--bl-secondary);
}

button:focus:not(:disabled):active .ripple, button:focus:not(:disabled) .ripple {
	opacity: .35;
	box-shadow: 0 3px 5px -1px var(--bl-secondary), 0 6px 10px 0 var(--bl-secondary), 0 1px 18px 0 var(--bl-secondary);
}

button.primary:not(:disabled):hover .ripple {
	opacity: .1;
	box-shadow: 0 2px 1px -1px var(--bl-primary), 0 1px 1px 0 var(--bl-primary), 0 1px 3px 0 var(--bl-primary);
}

button.primary:focus:not(:disabled):active .ripple, button.primary:focus:not(:disabled) .ripple {
	opacity: .35;
	box-shadow: 0 3px 5px -1px var(--bl-primary), 0 6px 10px 0 var(--bl-primary), 0 1px 18px 0 var(--bl-primary);
}

svg {
	position: absolute;
	right: 6px;
	top: 3px;
	animation: slide-left 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

button.outlined:not(:disabled) {
	background-color: transparent;
	color:  var(--bl-secondary);

	.ripple {
		background-color: var(--bl-secondary);
	}
}

button.outlined:disabled {
	background-color: transparent;
	color: var(--bl-border);
}

button.error {
	background-color: var(--bl-error);
	color: var(--bl-on-primary);
}

button.error:not(:disabled):hover .ripple {
	opacity: .1;
	box-shadow: 0 2px 1px -1px var(--bl-error), 0 1px 1px 0 var(--bl-error), 0 1px 3px 0 var(--bl-error);
}

button.error:focus:not(:disabled):active .ripple, button.error:focus:not(:disabled) .ripple {
	opacity: .35;
	box-shadow: 0 3px 5px -1px var(--bl-error), 0 6px 10px 0 var(--bl-error), 0 1px 18px 0 var(--bl-error);
}

button.error.outlined:not(:disabled) {
	background-color: transparent;
	color: var(--bl-error);

	.ripple {
		background-color: var(--bl-error);
	}
}

button.outlined.primary:not(:disabled) {
	color:  var(--bl-primary);

	.ripple {
		background-color: var(--bl-primary);
	}
}

button.outlined.legend:not(:disabled) {
	color:  var(--bl-legend);

	.ripple {
		background-color: var(--bl-legend);
	}
}

button.dense {
	padding: 0 10px;
	font-size: 14px;
	height: 30px;
	min-height: 30px;
	overflow: hidden;
	min-width: 0;
	letter-spacing: 0;
	font-family: Roboto;
	font-weight: 500;

	> span {
		margin-bottom: -1px;
	}
}

button.dense > i.material-icons {
	font-size: 14px;
	float: left;
	margin-right: 5px;
}

@keyframes slide-left {
	0% {
		transform: translateX(50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

button:has(> span) {
	min-width: 100px;

	icon {
		margin: 0 5px 0 0;
	}
}
</style>