<template>
	<div class="container">
		<div class="header">
			<div v-bl-input>
				<icon class="prefix" style="display: block;">search</icon>
				<input type="text" ref="field" v-model="search" @keyup="updateSearch()" />
				<icon class="suffix" style="display: block; cursor: pointer; margin-right: -30px;" @click="search = ''; updateSearch();" v-if="search">close</icon>
			</div>
			<BlButton :label="$t('New')" icon="person_add" class="outlined" @click="createNewGroupRoom()" />
		</div>
		<div class="bl-loader-line" v-if="currentRequest"></div>
		<ul class="rooms">
			<li v-for="room in filterRooms" :key="room.id" @click="open(room.id)" :class="{new: room.newMessages}">
				<div class="image" :class="{multiple: room.otherMembers.length > 1}">
					<BlProfilePicture v-for="member in room.otherMembers" :key="member" :showStatus="false" :id="member" />
				</div>
				<div style="flex: 1; max-width: calc(100% - 70px);">
					<b>{{ room.name }}</b>
					<em v-html="room.lastMessage"></em>
				</div>
				<div class="status" :class="room.status"></div>
			</li>
			<li v-for="user in filterUsers" :key="user.id" @click="createNewUserRoom(user)">
				<div class="image">
					<BlProfilePicture :showStatus="false" :id="user.id" />
				</div>
				<div style="flex: 1;">
					<b>{{ user.name }}</b>
				</div>
				<div class="status" :class="user.status"></div>
			</li>
		</ul>
	</div>
</template>

<script>
import { WindowManager, Realtime, ViewLoader, Dialog, EventEmitter, Router } from 'InterfaceBundle'
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { ChatManager } from 'ChatBundle'
import { UserStatus } from 'AuthBundle'

export default {
	name: 'BlChatRooms',
	props: ['mobile'],
	data() {
		return {
			rooms: [],
			users: [],
			filterRooms: [],
			filterUsers: [],
			currentRequest: true,
			modelChangeSub: null,
			newMessageSub: {}
		}
	},
	created() {
		this.loadRooms().subscribe(() => {
			this.currentRequest = false
			this.focusSubscription = ChatManager.focus.subscribe(roomId => {
				let room = this.rooms.filter(r => r.id == roomId)
				if(room.length) room[0].newMessages = false
			})
		})
		this.userStatusSub = UserStatus.change.subscribe(() => this.setStatus())

		this.modelChangeSub = ModelChangeEventHelpers.listen('internals.chatroom')
		this.modelChangeSub.subscribe(() => this.loadRooms())
	},
	unmounted() {
		for(let sub of Object.values(this.newMessageSub)) sub.unsubscribe()
		if(this.focusSubscription) this.focusSubscription.unsubscribe()
		ModelChangeEventHelpers.unsubscribe(this.modelChangeSub)
		this.userStatusSub.unsubscribe()
	},
	methods: {
		loadRooms() {
			let req = {'context("data"):script.run("internalsChatRoomList")': {
				rooms: 'local.data.rooms',
				users: 'local.data.users'
			}}
			let ret = new EventEmitter()
			Api.post('api/structure/', req).then(resp => {
				this.rooms = resp.rooms ? resp.rooms : []
				this.users = resp.users ? resp.users : []

				for(let room of this.rooms) {
					if(!this.newMessageSub[room.id]) {
						this.newMessageSub[room.id] = Realtime.listen('chat.message.' + room.id)
						this.newMessageSub[room.id].then(message => {
							room.lastMessage = message.content
							room.lastMessageDate = new Date()
							if(message.from != ViewLoader.data.userPreferences.id && ChatManager.activeRoom != room.id) room.newMessages = true
							this.sortRooms()
						})
					}
				}
				this.sortRooms()
				ret.emit()
			})
			return ret
		},
		setStatus() {
			for(let room of this.rooms) {
				room.status = null
				for(let user of room.otherMembers) {
					let status = UserStatus.getStatus(user)
					if(!room.status) room.status = status
					else if(status == 'idle' && room.status != 'active') room.status = 'idle'
					else if(status == 'active') room.status == 'active'
				}
			}
			for(let user of this.users) user.status = UserStatus.getStatus(user.id)
		},
		open(roomId) {
			if(this.mobile) {
				Router.navigate(['mobile-chatroom', {id: roomId}])
				return
			}
			let exists = false
			for(let item of WindowManager.windows) {
				if(item.component == 'BlChatRoomWindowContainer' && item.properties.roomId == roomId) {
					exists = true
					break
				}
			}
			if(exists) ChatManager.focus.emit(roomId)
			else WindowManager.open('BlChatRoomWindowContainer', {roomId: roomId})
		},
		sortRooms() {
			this.setStatus()
			this.rooms = this.rooms.map(r => {
				if(r.lastMessageDate && typeof r.lastMessageDate === 'string') r.lastMessageDate = new Date(r.lastMessageDate)
				return r
			})
			this.rooms.sort((a, b) => {
				if(!a.lastMessageDate) return 1
				if(!b.lastMessageDate) return -1
				return a.lastMessageDate > b.lastMessageDate ? -1 : 1
			})
			this.updateSearch()
		},
		createNewGroupRoom() {
			Dialog.custom({
				component: 'InternalsChatGroupRoomForm'
			}).then(resp => {
				if(resp && resp.__id) this.open(resp.__id)
			})
		},
		createNewUserRoom(user) {
			let req = {}
			req['context("data"):script.run("internalsChatNewUserRoom", ' + user.id + ')'] = {
				id: 'local.data.id'
			}
			Api.post('api/structure/', req).then(resp => this.open(resp.id))
		},
		updateSearch() {
			this.filterRooms = this.rooms
			this.filterUsers = this.users
			if(this.search) {
				this.filterRooms = this.filterRooms.filter(r => this.normalize(r.name).includes(this.normalize(this.search)))
				this.filterUsers = this.filterUsers.filter(r => this.normalize(r.name).includes(this.normalize(this.search)))
			}
		},
		normalize(string) {
			return (string + '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
		}
	}
}
</script>

<style scoped lang="scss">
	.container {
		margin-top: -10px;
		margin-left: -10px;
		width: calc(100% + 20px);
	}

	.header {
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--bl-border);

		.bl-input {
			margin-left: 10px;
			margin-bottom: 10px;
			margin-top: 4px;
			margin-bottom: 8px;

			icon.prefix {
				margin-top: 10px;
			}

			input {
				padding-left: 37px;
			}
		}

		button.outlined {
			margin-left: 45px;
			margin-right: 8px;
		}
	}

	.rooms {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			padding: 10px;
			cursor: pointer;
			display: flex;
			transition: background-color .2;
			border-bottom: 1px solid var(--bl-border);
			align-items: center;

			b {
				font-family: 'Product sans';
				font-weight: 400;
				font-size: 14px;
				display: block;
			}

			.image {
				width: 37px;
				height: 37px;
				position: relative;
				margin-right: 10px;
			}

			.image.multiple > div:nth-child(1) {
				transform-origin: top left;
				transform: scale(.7);
				position: absolute;
				top: 0;
				left: 11px;
			}

			.image.multiple > div:nth-child(2) {
				transform-origin: top left;
				transform: scale(.7);
				position: absolute;
				top: 11px;
				z-index: 2;
				outline: 3px solid white;
			}

			em {
				color: var(--bl-legend);
				font-style: normal;
				font-size: 12px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-height: 18px;
				display: block;
				padding-right: 10px;
				line-height: 18px;
				margin-bottom: -2px;
			}

			div.status {
				transition: background-color .2s;
				background-color: var(--bl-legend);
				border-radius: 50%;
				width: 8px;
				height: 8px;
				margin-right: 15px;
				display: none;
			}

			div.status.active {
				background-color: #59B04D;
				display: block;
			}

			div.status.idle {
				background-color: #EB9C14;
				display: block;
			}
		}

		li.new {
			em {
				font-weight: 500;
			}

			b {
				font-weight: 600;
			}
		}

		li:hover {
			background-color: var(--bl-background);
		}
	}
</style>