<template>
	<div v-if="log">
		<h2 :class="log.titletype">
			<div v-if="log.typeDetail" class="typeDetail">{{ log.typeDetail }}</div>
			<div>{{ log.title }}</div>
		</h2>
		<div style="display: flex; align-items: center;">
			<component :is="log.type._cn" v-bind="log.type._cp" />
			<b style="margin-left: 10px;">#{{ log.channel }}</b>
			<em>{{ log.date }}</em>
		</div>
		<pre v-html="log.content"></pre>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	props: ['id'],
	name: 'BlLogDetail',
	watch: {
		id() {
			this.loadData()
		}
	},
	data() {
		return {
			log: null
		}
	},
	created() {
		this.loadData()
	},
	methods: {
		loadData() {
			Api.post('api/one/', {
				model: 'internals.log',
				id: this.id,
				fields: [{name: 'title'}, {name: 'titletype'}, {name: 'date'}, {name: 'type'}, {name: 'content'}, {name: 'channel'}]
			}).then(resp => {
				this.log = resp.data
				if(this.log.titletype.split('.').length > 1) {
					this.log.typeDetail = this.log.titletype.split('.')[1]
					this.log.titletype = this.log.titletype.split('.')[0]
				}
			})
		}
	}
}
</script>

<style scoped lang="scss">
	h2 {
		font-family: Product sans;
		font-weight: 600;
		display: flex;
		align-items: center;

		.title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.title.boolean, .title.double, .title.integer {
			color: #9e3379;
		}

		.title.string::before, .title.string::after {
			content: "'";
		}

		.title.array, .title.object, .title.instance, .typeDetail {
			color: #3c3cbf;
		}

		.typeDetail {
			font-size: 14px;
			font-weight: 600;
			border-radius: 2px;
			padding-right: 10px;
			font-style: italic;
		}

		.logTypeIcon {
			margin-right: 4px;
			font-size: 14px;
		}
	}

	span {
		border-radius: var(--bl-border-radius);
		font-weight: 500;
		padding: 2px 5px;
		display: flex;
		align-items: center;

		i {
			font-size: 18px;
			margin-right: 4px;
		}
	}

	em {
		font-style: normal;
		color: var(--bl-legend);
		margin-left: 8px;
	}

	pre {
		font-size: 12px;
		white-space: pre-line;
	}
</style>