import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Plugins
 */
import IntlPlugin from './plugins/Intl.js'
Registry.use(IntlPlugin)

/**
 * Components
 */

/**
 * Directives
 */

/**
 * Services
 */
import { Signal as SignalService } from './services/Signal.js'

export const Signal = SignalService