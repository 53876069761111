<template>
	<div style="display: flex; height: calc(100% - 5px);">
		<div :style="{width: logDetailsWidth + 'px', flex: log ? 'none' : '1'}" style="overflow: auto;" class="bl-light-scroll bl-list-container">
			<BlList model="internals.log" ref="blList" @clickLine="loadLog($event)" defaultSort="-date">
				<BlListField name="date" filter="range" />
				<BlListField name="type" filter="select" />
				<BlListField name="channel" filter="search" />
				<BlListField name="title" filter="search" />
				<template #title="log">
					<div style="font-family: monospace; max-width: 250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ log.title }}</div>
				</template>
			</BlList>
		</div>
		<div v-if="log" v-bl-resize="{value: logDetailsWidth, min: 666}" @resize="logDetailsWidth = $event" style="height: calc(100% + 5px);"></div>
		<div v-if="log" :style="{width: 'calc(100% - ' + (logDetailsWidth + 10) + 'px)'}">
			<div class="bl-card bl-light-scroll" style="max-width: 100%; overflow-x: auto;">
				<BlLogDetail :id="log.id" />
			</div>
		</div>
	</div>
</template>

<script>
import { ModelChangeEventHelpers } from 'ModelBundle'

export default {
	name: 'BlLogAnalyser',
	data() {
		return {
			logDetailsWidth: 666,
			log: null
		}
	},
	created() {
		this.realtimeSub = ModelChangeEventHelpers.listen('custom.log.new')
		this.realtimeSub.subscribe(() => this.$refs.blList.loadData())
	},
	unmounted() {
		this.realtimeSub.unsubscribe()
	},
	methods: {
		loadLog(item) {
			this.log = item
		}
	}
}
</script>

<style scoped lang="scss">
	.bl-list-container :deep .bl-datatable {

		tbody {
			font-size: 12px;

			tr td {
				padding: 7px 4px;
				cursor: pointer;
			}
		}
	}
</style>
