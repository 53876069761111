<template>
	<div style="position: relative;" :class="{desktop: !mobileFilter}">
		<div v-bl-input>
			<input readonly type="text" v-model="value" @focus="toggleCalendar()" ref="inputElement" />
			<span class="suffix material-icons" @click="clear()" style="display: block; margin-top: 10px;">{{ hasValue() ? 'remove_circle_outline' : '' }}</span>
		</div>
		<BlCalendarInput :value="value" @change="value = $event; setFilter()" :format="'yyyy-MM-dd'" :opened="showCalendar" @openedChange="openedChange($event)" :range="true" />
	</div>
</template>

<script>
export default {
	name: 'BlListFilterRangeDate',
	props: ['initialValue', 'metadata', 'mobileFilter'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: null,
			showCalendar: false
		}
	},
	created() {
		this.value = this.initialValue
	},
	methods: {
		toggleCalendar() {
			this.showCalendar = !this.showCalendar
		},
		setFilter() {
			if(this.hasValue()) this.$emit('blListFilterChange', [['&', [this.metadata.field.name, '>=', this.value[0]], [this.metadata.field.name, '<=', this.value[1]]], this.value])
			else this.$emit('blListFilterChange', null)
		},
		hasValue() {
			return this.value && this.value[0] && this.value[1]
		},
		clear() {
			this.value = null
			this.setFilter()
		},
		openedChange(value) {
			this.showCalendar = value
			if(document.activeElement === this.$refs.inputElement) this.showCalendar = true
		}
	}
}
</script>

<style scoped lang="scss">
	.suffix {
		cursor: pointer;
	}

	.desktop {
		width: calc(100% + 8px);

		div.bl-input .suffix {
			margin-right: -35px;
		}

		div.bl-input.__suffix {
			width: calc(100% - 51px);
		}

		div.bl-input input {
			padding-right: 35px;
		}
	}
</style>