<template>
	<div class="bl-checkbox-field" :class="{errored: field.isErrored() && field.getTouched()}">
		<input class="bl-checkbox" type="checkbox" v-model="model" ref="checkbox" @change="field.setValue(model)" @blur="field.setTouched()" :tabindex="tabindex" />
		<label @click="toggle()">{{ field.label }}</label>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldBoolean',
	props: ['field', 'tabindex'],
	data() {
		return {
			model: this.field.value && this.field.value != 0 ? true : false
		}
	},
	methods: {
		toggle() {
			this.model = !this.model
			this.$refs.checkbox.focus()
			this.field.setValue(this.model)
		}
	},
	mounted() {
		this.field.emitter.focus.subscribe(() => this.$el.querySelector('input[type="checkbox"]').focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	}
}
</script>

<style scoped lang="scss">
label {
	cursor: pointer;
	padding-left: 6px;
	padding-top: 4px;
	user-select: none;
}

div {
	display: flex;
}

.errored {
	label {
		color: var(--bl-error);
	}

	input.bl-checkbox::after {
		border-color: var(--bl-error);
	}
}
</style>