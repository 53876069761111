import { ViewServices, Dialog, Snackbar } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export const Document = {
	/**
	 * Print document
	 * @param  {string} documentReference
	 * @param  {Object} args
	 */
	print(documentReference, args = {}) {
		this._print(documentReference, args, 'document/get/', false)
	},

	/**
	 * Print multiple documents
	 * @param  {string} documentReference
	 * @param  {array}  args
	 */
	printMulti(documentReference, args) {
		this._print(documentReference, {data: JSON.stringify(args)}, 'document/multi/get/', true)
	},

	_print(documentReference, args, url, multi) {
		let element = document.createElement('iframe')
		Dialog.custom({
			closeButton: false,
			component: 'BlDocumentPrintLoading',
			componentProps: {name: documentReference, multi: multi},
			handleRouting: false
		}).catch(() => {
			element.onload = null
		})
		element.style.display = 'none'
		element.setAttribute('src', Api.getUrl(url + documentReference, args))
		document.body.appendChild(element)
		element.onload = () => {
			element.contentWindow.print()
			let event = window.addEventListener('focus', () => {
				Dialog.close(true, false)
				if(element && element.parentNode == document.body) document.body.removeChild(element)
				window.removeEventListener('focus', event)
			})
		}
	},

	/**
	 * Download document
	 * @param  {string} documentReference
	 * @param  {Object} args
	 */
	download(documentReference, args = {}) {
		Api.get('/document/name/' + documentReference, args).then(meta => {
			let snackbar = Snackbar.open({
				clickToClose: false,
				autoClose: false,
				component: 'InterfaceFileSnackbar',
				componentProps: {
					name: meta.name + '.pdf',
					icon: meta.extension[1]
				}
			})

			let fileName = null
			fetch(Api.getUrl('document/download/' + documentReference, args))
				.then(resp => {
					fileName = resp.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"', '')
					return resp.blob()
				})
				.then(blob => {
					let url = window.URL.createObjectURL(blob)
					let a = document.createElement('a')
					a.style.display = 'none'
					a.href = url
					a.download = fileName
					document.body.appendChild(a)
					a.click()
					window.URL.revokeObjectURL(url)
					snackbar.hide()
				})
				.catch(() => {
					snackbar.hide()
				})
		})
	},

	/**
	 * Download multiple documents
	 * @param  {string} documentReference
	 * @param  {array}  args
	 */
	downloadMulti(documentReference, args) {
		for(let arg of args) this.download(documentReference, arg)
	},

	/**
	 * Open document
	 * @param  {string} documentReference
	 * @param  {Object} args
	 */
	open(documentReference, args = {}) {
		this._open(documentReference, args, 'document/get/')
	},

	/**
	 * Open multiple documents
	 * @param  {string} documentReference
	 * @param  {Array} array of each document args
	 */
	openMulti(documentReference, args) {
		this._open(documentReference, {data: JSON.stringify(args)}, 'document/multi/get/')
	},

	_open(documentReference, args, url) {
		Dialog.custom({
			fullScreen: true,
			closeButton: false,
			component: 'BlDocumentDialogPreview',
			componentProps: {src: Api.getUrl(url + documentReference, args)}
		}).then(() => {}).catch(() => {})
	}
}

ViewServices.document = Document