import { EventEmitter } from 'InterfaceBundle'

/**
 * Represent an instance of a form, and all its children
 */
export class BlForm {
	constructor(type, name, label = null, options = [], validation = [], root, value = null) {
		this.type = type
		this.name = name
		this.label = label ? label : name
		this.options = options
		this.validation = validation
		this.children = []
		this.updateOnLinkField = {}
		this.hasLinkedFields = false
		this.messages = []
		this.key = name
		this.root = root
		if(this.root) {
			if(!this.root._uuid) this.root._uuid = 1
			this.uuid = this.root._uuid
			this.root._uuid++
		}
		this.pristine = true
		this.changed = false
		this.autoAdd = false
		this.parent = null
		let hasValue = value || value === false || value === 0
		if(hasValue) this.value = value
		else if(this.options.default) this.setValue(this.options.default, false)
		this.initializeLinkedFields()
		this.emitter = {
			change: new EventEmitter(),
			focus: new EventEmitter(),
			beforeSubmit: new EventEmitter(),
			beforeValidation: new EventEmitter(),
			dataChange: new EventEmitter()
		}
		this.initialize()
		if(this.options.forceLinkedField) {
			for(let forceLF of this.options.forceLinkedField) this.addUpdateOnLinkField(forceLF.getQualifiedName())
			this.updateLinkedFields()
		}
	}

	initialize() {
		if(this.options.required && this.label[this.label.length -1] != '*') this.label += '*'
	}

	/**
	 * Get if field should be shown
	 * @return {boolean}
	 */
	show() {
		return this.options.show === 'undefined' || this.options.show !== false
	}

	initializeLinkedFields() {
		if(this.options.linked_fields) {
			this.root.$nextTick(() => {
				let hasLinkedFields = false
				for(let field of this.options.linked_fields) {
					const fieldParts = field.split('.')
					let fieldPartsC = field.split('.')
					let item = this
					for(let fieldPart of fieldParts) {
						if(fieldPart == 'parent' && !item.getChild(fieldPart)) item = item.parent
						else if(item.type == 'collection' && !item.getChild(fieldPart)) {
							let target = item

							//Existing values
							for(let val of item.value) {
								for(let subFieldPart of fieldPartsC) val = val?.getChild(subFieldPart)
								if(val && val.uuid !== this.uuid) val.addUpdateOnLinkField(this.getQualifiedName())
							}

							for(let subFieldPart of fieldPartsC) target = target.options.metadata[subFieldPart]
							if(target) {
								if(!target.options.forceLinkedField) target.options.forceLinkedField = []
								target.options.forceLinkedField.push(this)
							}
							item = null
						}
						else item = item.getChild(fieldPart)
						fieldPartsC.shift()
						if(!item) break
					}
					if(item) {
						item.addUpdateOnLinkField(this.getQualifiedName())
						hasLinkedFields = true
					}
				}

				//Handle subForm new items
				if(hasLinkedFields && !this.root.form.pristine) {
					let request = {}
					request[this.getQualifiedName()] = {loadAll: true}
					this.root.liveUpdateFields(request)
				}
			})
		}
	}

	/**
	 * Get qualified name
	 * @return {string}
	 */
	getQualifiedName() {
		let item = this
		let ret = []
		while(item && item.name != '_root') {
			if(item.type == 'collection') {
				ret.unshift(item.name + '[' + item.key + ']')
				if(item.parent.parent && item.parent.parent.key != '_root' && item.parent.name != item.name) item = item.parent
				else item = item.parent.parent
			}
			//oneToOne
			else if(item.type == 'oneToOne') {
				ret.unshift(item.name)
				item = item.parent?.parent
			}
			else {
				ret.unshift(item.name)
				item = item.parent
			}
		}

		return ret.join('.')
	}

	/**
	 * Get child by qualified name
	 * @param  {string} name
	 * @return {BlForm}
	 */
	getChildByQualifiedName(name) {
		let parts = name.split('.')
		let ret = this
		for(let part of parts) {
			if(part.includes('[')) {
				let subParts = part.split('[')
				subParts[1] = subParts[1].slice(0, -1)
				ret = ret.getChild(subParts[0])
				for(let subValue of ret.value) {
					if(subValue.key == subParts[1]) ret = subValue
				}
			}
			else ret = ret.getChild(part)
		}
		return ret
	}

	/**
	 * Add child to the item
	 * @param {BlForm} child
	 */
	addChild(child) {
		this.children.push(child)
		child.parent = this
	}

	/**
	 * Get child by name
	 * @param  {string} name
	 * @return {BlForm}
	 */
	getChild(name) {
		for(let child of this.children) {
			if(child.name == name) return child
		}
		return null
	}

	/**
	 * Add update on link field
	 * @param {string} field
	 */
	addUpdateOnLinkField(field) {
		this.updateOnLinkField[field] = {}
		if(typeof this.options.loadAll !== 'undefined') this.updateOnLinkField[field] = {loadAll: true}
		this.hasLinkedFields = true
	}

	/**
	 * Update linked fields
	 */
	updateLinkedFields() {
		if(this.hasLinkedFields) {
			this.root.liveUpdateFields(this.updateOnLinkField)
		}
	}

	/**
	 * Set child value
	 * @param {mixed} value
	 */
	setValue(value, fromEvent = true) {
		if(this.value === value) return
		this.value = value
		if(fromEvent) {
			this.updateLinkedFields()
			this.setTouched()
			this.emitChange()
			this.setChanged()
		}
		this.messages = []
	}

	emitChange() {
		this.emitter.change.emit()
		this.emitter.dataChange.emit()
		if(this.parent) this.parent.emitChange()
	}

	/**
	 * Data change event management
	 * @return {[type]} [description]
	 */
	dataChange() {
		this.emitter.dataChange.emit()
		if(this.parent) this.parent.dataChange()
	}

	/**
	 * Add value in collecion field
	 * @param {integer} key optionnal, represents the key of the objet
	 * @return {BlForm}
	 */
	addValue(key = null, setTouched = true) {
		if(!Array.isArray(this.value)) this.value = []
		let item = new BlForm(this.type, this.name, this.label, this.options, this.validation, this.root)
		item.parent = this
		this.root.buildForm(item, this.options.metadata)
		let existingIds = this.value.filter(v => v.key.substr(0, 4) == '_new').map(v => parseInt(v.key.substr(4)))
		let newValue = existingIds.length ? (Math.max(...existingIds) + 1) : 1
		item.key = key ? key : ('_new' + newValue)
		this.value.push(item)
		if(setTouched) this.setTouched()
		this.dataChange()
		return item
	}

	/**
	 * Remove value from collection field at index
	 * @param  {number} index
	 */
	removeValue(index) {
		if(!Array.isArray(this.value)) this.value = []
		//@todo : recursive removal
		if(this.value[index]) this.value[index].onRemove()
		this.value = this.value.filter(v => v !== this.value[index])
		this.dataChange()
	}

	/**
	 * On remove item
	 */
	onRemove() {
		if(this.type == 'collection' && Array.isArray(this.value)) {
			for(let value of this.value) value.onRemove()
		}
		else {
			for(let child of this.children) child.onRemove()
			if(this.options.forceLinkedField) this.updateLinkedFields()
		}
	}

	/**
	 * Get component name
	 * @return {string}
	 */
	get component() {
		return 'BlFormField' + this.type.charAt(0).toUpperCase() + this.type.slice(1)
	}

	/**
	 * Get form data
	 * @return {mixed}
	 */
	get data() {
		if(this.type == 'form' || this.type == 'oneToOne') {
			let ret = {}
			for(let child of this.children) {
				if(child.show()) ret[child.key] = child.data
			}
			return ret
		}
		else if(this.type == 'collection') {
			if(!Array.isArray(this.value)) return []
			let ret = {}
			for(let item of this.value) {
				let line = {}
				for(let subChild of item.children) line[subChild.key] = subChild.data
				ret[item.key] = line
			}
			return ret
		}
		else return this.value
	}

	/**
	 * Get if form is valid
	 * @return {Boolean}
	 */
	isValid() {
		if(!this.show()) return true
		if(this.messages.length) return false
		//@todo : move validation to field specific files
		if(this.type == 'collection' && this.parent.type != 'collection') {
			if(this.options.required && !this.value.length) return false
			for(let child of this.value) {
				if(!child.isValid()) return false
			}
			return true
		}
		if(this.children.length) {
			for(let child of this.children) {
				if(!child.isValid()) return false
			}
			return true
		}
		if(this.options.required && ((!this.value && this.value !== 0 && this.value !== false) || (Object.prototype.toString.call(this.value) === '[object Array]' && JSON.stringify(this.value) == '[]') || this.messages.length)) return false
		return true
	}

	/**
	 * Get if form is errored
	 * @return {Boolean}
	 */
	isErrored() {
		return !this.isValid()
	}

	/**
	 * Get if form is touched
	 * @return {Boolean}
	 */
	getTouched() {
		return !this.pristine
	}

	/**
	 * Set form as touched
	 */
	setTouched() {
		this.pristine = false
		if(this.parent) this.parent.setTouched()
	}

	/**
	 * Set form as changed
	 */
	setChanged() {
		this.changed = true
		if(this.parent) this.parent.setChanged()
	}

	/**
	 * Flatten form and children
	 * @return {Array}
	 */
	flatten() {
		let ret = []
		if(this.type == 'collection' && this.parent.type != 'collection') {
			for(let child of this.value) ret = ret.concat(child.flatten())
		}
		else if(this.children.length) {
			for(let child of this.children) ret = ret.concat(child.flatten())
		}
		else ret.push(this)
		return ret
	}

	bindMessages(messages) {
		for(let field in messages) {
			if(Array.isArray(messages[field])) this.getChild(field).messages = messages[field]
			else {
				if(this.type == 'collection') {
					for(let item of this.value) {
						if(item.key == field) {
							item.bindMessages(messages[field])
							break
						}
					}
				}
				else this.getChild(field).bindMessages(messages[field])
			}
		}
	}

	setAutoAdd(autoAdd) {
		this.autoAdd = autoAdd
	}

	initializeMove(field) {
		if(this._moveInitialized) return
		this._moveInitialized = true
		this.root.form.emitter.beforeSubmit.subscribe(() => {
			let pos = 1
			for(let item of this.value) {
				item.getChild(field).setValue(pos, false)
				pos++
			}
		})
	}

	updateOptions(newOptions) {
		let keep = []
		if(this.options.forceLinkedField) keep.push(['forceLinkedField', this.options.forceLinkedField])
		this.options = newOptions
		for(let item of keep) this.options[item[0]] = item[1]
	}

	destroy() {
		for(let key in this.emitter) this.emitter[key].clear()
	}
}