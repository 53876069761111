<template>
	<div v-bl-input :class="{errored: field.isErrored() && field.getTouched()}">
		<label>{{ field.label }}</label>
		<input autocomplete="new-password" :type="mode" v-model="model" @change="field.setValue(model)" @blur="field.setTouched()" ref="field" :tabindex="tabindex" />
		<span class="suffix material-icons" @click="toggleMode()" style="display: block;">{{ mode == 'password' ? 'visibility' : 'visibility_off' }}</span>
	</div>
</template>

<script>
export default {
	name: 'BlFormFieldPassword',
	props: ['field', 'tabindex'],
	data() {
		return {
			mode: 'password',
			model: this.field.value
		}
	},
	created() {
		this.field.emitter.focus.subscribe(() => this.$refs.field.focus())
		this.field.emitter.change.subscribe(() => this.model = this.field.value)
	},
	methods: {
		toggleMode() {
			this.mode = this.mode == 'password' ? 'text' : 'password'
		}
	}
}
</script>

<style scoped lang="scss">
	div.bl-input .suffix.material-icons {
		font-size: 20px;
		margin-top: 12px;
		cursor: pointer;
	}
</style>