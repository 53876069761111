<template>
	<div class="bl-card filterContainer">
		<div>
			<div style="display: flex; align-items: baseline; margin-bottom: -15px;">
				<h1 style="flex: 1;">
					<icon>filter_alt</icon>
					{{ $t('model.list.filters') }}
				</h1>
				<button class="bl-icon-button" @click="close()">close</button>
			</div>
			<div class="bl-light-scroll" style="flex: 1; overflow-x: auto; padding: 0 2px;">
				<div v-for="col in blList.fieldNames" :key="col" class="mobileFiltersContainer">
					<template v-if="blList.fieldOptions[col].filter">
						<h5>{{ blList.getLabel(col) }}</h5>
						<component :is="blList.getFilterComponentName(col)" :mobileFilter="true" :initialValue="blList.filtersState[col]" :metadata="{field: blList.metadata[col], model: blList.modelMeta}" :options="blList.getFilterOptions(col)" @blListFilterChange="filtersToApply[col] = $event" />
					</template>
				</div>
			</div>
			<div style="text-align: right; margin: 10px 1px 6px 1px;">
				<BlButton style="width: 100%;" :label="$t('model.list.applyFilters')" @click="apply()" />
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'InterfaceBundle'

export default {
	name: 'BlListMobileFilters',
	props: ['blList'],
	data() {
		return {
			filtersToApply: {}
		}
	},
	methods: {
		close() {
			Dialog.close(false)
		},
		apply() {
			let loadData = false
			for(let col in this.filtersToApply) {
				this.blList.updateFilter(col, this.filtersToApply[col], false)
				loadData = true
			}
			if(loadData) this.blList.loadData()
			Dialog.close(true)
		}
	}
}
</script>

<style scoped lang="scss">
	.filterContainer {
		width: 80%;
		margin: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border: 0;
		pointer-events: default;
		animation: slideLeft .2s;
		animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
		position: relative;

		> div {
			margin: 10px;
			display: flex;
			flex-direction: column;
			height: calc(100% - 15px);
		}

		h5 {
			font-family: Product sans;
			font-weight: 600;
			font-size: 14px;
			margin: 15px 0 0 0;
		}
	}

	@keyframes slideLeft {
		from {
			right: -100%;
		}

		to {
			right: 0;
		}
	}
</style>