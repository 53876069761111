<template>
	<button class="bl-icon-button" type="button" @click="blFormCollectionValue.removeValue(blFormCollectionIndex())" v-if="show()" tabindex="-1" @mouseover="handleOver()" @mouseout="handleOut()">{{ icon }}</button>
</template>

<script>
export default {
	name: 'BlCollectionRemove',
	inject: ['blFormCollectionValue', 'blFormCollectionIndex'],
	props: {
		icon: {
			type: String,
			default: 'delete'
		}
	},
	methods: {
		show() {
			return !this.blFormCollectionValue.options.required || this.blFormCollectionValue.value.length > 1
		},
		handleOver() {
			if(this.$el.parentElement && this.$el.parentElement.parentElement && this.$el.parentElement.parentElement.tagName == 'TR') this.$el.parentElement.parentElement.classList.add('bl-remove-hover')
		},
		handleOut() {
			if(this.$el.parentElement && this.$el.parentElement.parentElement && this.$el.parentElement.parentElement.tagName == 'TR') this.$el.parentElement.parentElement.classList.remove('bl-remove-hover')
		}
	}
}
</script>

<style scoped lang="scss">
button {
	padding: 4px;
}
</style>