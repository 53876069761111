import { Container } from './../../../main.js'

export const Registry = {
	componentCache: [],
	directiveCache: [],
	pluginCache: [],
	initialized: false,

	use(plugin) {
		if(Container) Container.use(plugin)
		else {
			this.pluginCache.push(plugin)
			this.handleCache()
		}
	},

	/**
	 * Register component
	 * @param  {mixed}  component
	 */
	component(component) {
		if(Container) {
			Container.component(component.name, component)
		}
		else {
			this.componentCache.push(component)
			this.handleCache()
		}
	},

	/**
	 * Register multiple components
	 */
	components() {
		for(let component of arguments) this.component(component)
	},

	/**
	 * Register directive
	 * @param  {string} name
	 * @param  {mixed}  directive
	 */
	directive(name, directive) {
		if(Container) Container.directive(name, directive)
		else {
			this.directiveCache.push([name, directive])
			this.handleCache()
		}
	},

	handleCache() {
		if(!this.initialized) {
			this.initialized = true
			setTimeout(() => {
				for(let component of this.componentCache) Container.component(component.name, component)
				for(let directive of this.directiveCache) Container.directive(directive[0], directive[1])
				for(let plugin of this.pluginCache) Container.use(plugin)
			})
		}
	}
}