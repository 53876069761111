import { ScriptLoader } from 'InterfaceBundle'

export var MonacoLoader = {
	/**
	 * Load editor
	 */
	loadEditor(cb) {
		if(window.monaco) {
			cb()
			return
		}
		const cdnPrefix = 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.35.0/min/vs/'
		ScriptLoader.importJS(cdnPrefix + 'loader.min.js').then(() => {
			ScriptLoader.importCSS(cdnPrefix + 'editor/editor.main.min.css').then(() => {
				window.require.config({ paths: { 'vs': 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.35.0/min/vs' }})
				window.require(['vs/editor/editor.main'], () => {
					cb()
					const requires = [
						'language/json/jsonMode.js',
						'language/css/cssMode.js',
						'basic-languages/css/css.js',
						'language/html/htmlMode.js',
						'basic-languages/html/html.js',
						'basic-languages/python/python.js',
						'basic-languages/javascript/javascript.js',
						'basic-languages/yaml/yaml.js'
					]
					window.require(requires.map(r => cdnPrefix + r), () => {})
				})
			})
		})
	}
}