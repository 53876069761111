<template>
	<div style="position: relative;">
		<BlSelect :data='data' v-model="value" @change="setFilter()" :loading="loading" :combo="true" />
		<span v-if="value && value.length" class="suffix material-icons clear" @click="clear()" style="display: block; margin-top: 10px;">remove_circle_outline</span>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'

export default {
	name: 'BlListFilterSelectString',
	props: ['initialValue', 'metadata', 'options'],
	emits: ['blListFilterChange'],
	data() {
		return {
			value: [],
			loading: true,
			data: {}
		}
	},
	created() {
		this.value = this.initialValue
		if(!this.value) this.value = []

		let req = {
			data: {
				model: this.metadata.model.name,
				fields: [{name: this.metadata.field.name}],
				limit: -1,
				groupBy: [this.metadata.field.name]
			}
		}

		this.data = {}

		Api.post('api/', req).then(resp => {
			this.data = {}
			this.data['<em style="padding-right: 5px; color: var(--bl-legend);">' + this.$t('model.list.filterSelectAll') + '</em>'] = null
			for(let item of resp.data.data) this.data[item[this.metadata.field.name]] = item[this.metadata.field.name]
			this.loading = false
		})
	},
	methods: {
		setFilter() {
			if(this.value && this.value.length) this.$emit('blListFilterChange', [[this.metadata.field.name, 'IN', this.value], this.value])
			else this.$emit('blListFilterChange', null)
		},
		clear() {
			this.value = []
			this.setFilter()
		}
	}
}
</script>

<style scoped lang="scss">
	span.suffix.clear {
		position: absolute;
		left: 100%;
		margin-left: -33px;
		top: 0;
		background-color: var(--bl-surface);
		cursor: pointer;
	}
</style>