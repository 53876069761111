import { Registry } from 'InterfaceBundle'

/**
 * Style
 */

/**
 * Components
 */
import BlChatRoom from './components/ChatRoom.vue'
import BlChatRooms from './components/ChatRooms.vue'
import BlChatRoomWindowContainer from './components/ChatRoomWindowContainer.vue'

Registry.components(BlChatRoom, BlChatRooms, BlChatRoomWindowContainer)

/**
 * Directives
 */

/**
 * Services
 */
import { ChatManager as ChatManagerService } from './services/ChatManager.js'

export const ChatManager = ChatManagerService

/**
 * Virtual scroller
 */
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
Registry.components(DynamicScroller, DynamicScrollerItem)
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'