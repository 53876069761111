<template>
	<div class="fileView" v-if="file">
		<div class="hidePopoutButton" v-if="iframeSrc && iframeSrc.includes('google')" />
		<div class="topBarShadow" />
		<div class="topBar">
			<img :src="'https://static.mixsuite.fr/file_icons/' + file.icon + '.svg'" />
			<h4>{{ file.name }}</h4>
			<button v-if="file.navigation" class="bl-icon-button" v-bl-tooltip="$t('file.actions.previousFile')" @click.stop="setFile(file.navigation[1])">navigate_before</button>
			<button v-if="file.navigation" class="bl-icon-button" v-bl-tooltip="$t('file.actions.nextFile')" @click.stop="setFile(file.navigation[0])">chevron_right</button>
			<button class="bl-icon-button" v-bl-tooltip="$t('file.actions.download')" @click.stop="download()">file_download</button>
			<button class="bl-icon-button" v-bl-tooltip="$t('file.actions.close')" @click.stop="close()">close</button>
		</div>
		<div class="bl-loader-line" style="z-index: 2;" v-if="!iframeLoaded"></div>
		<iframe v-if="showIframe" ref="iframe" :src="iframeSrc" :class="{microsoft: iframeSrc.includes('live.com')}" />
	</div>
</template>

<script>
import { Auth } from 'AuthBundle'
import { Dialog } from 'InterfaceBundle'
import { Api } from 'ModelBundle'

export default {
	name: 'BlFileViewDialog',
	props: ['id', 'name', 'icon', 'embededViewerUrl', 'navigation'],
	data() {
		return {
			iframeSrc: '',
			iframeLoaded: false,
			showIframe: true,
			reloadTimeoutInstance: null,
			file: null
		}
	},
	methods: {
		close() {
			Dialog.close()
		},
		download() {
			Api.openWindow('file/' + this.file.id)
		},
		reloadTimeout() {
			this.reloadTimeoutInstance = setTimeout(() => {
				if(this.$refs.iframe.contentWindow[0]) {
					this.iframeLoaded = true
					clearTimeout(this.reloadTimeoutInstance)
					this.reloadTimeoutInstance = null
				}
				else {
					this.showIframe = false
					setTimeout(() => {
						this.showIframe = true
						this.reloadTimeout()
					}, 10)
				}
			}, 1000)
		},
		keydownHandler(event) {
			if(event.key == 'ArrowRight' && this.file.navigation) this.setFile(this.file.navigation[1])
			else if(event.key == 'ArrowLeft' && this.file.navigation) this.setFile(this.file.navigation[0])
		},
		setFile(file) {
			this.file = file
			this.iframeLoaded = false
			//Destroy and recreate iframe to avoid browser to push new state and mess up routing handler
			this.showIframe = false
			this.$nextTick(() => {
				this.showIframe = true
				let fullPath = window.location.origin + '/api/file/' + this.file.id + '?bearer=' + Auth.getToken()
				this.iframeSrc = this.file.embededViewerUrl.replace('{{url}}', encodeURIComponent(fullPath)).replace('{{token}}', Auth.getToken())
				this.$nextTick(() => {
					this.$refs.iframe.onload = () => this.iframeLoaded = true
					if(this.iframeSrc.substr(0, 5) != '/api/') this.reloadTimeout()
				})
			})
		}
	},
	unmounted() {
		if(this.reloadTimeoutInstance) clearTimeout(this.reloadTimeoutInstance)
		window.removeEventListener('keydown', this.keydownEventListener)
	},
	mounted() {
		this.setFile({
			id: this.id,
			name: this.name,
			icon: this.icon,
			embededViewerUrl: this.embededViewerUrl,
			navigation: this.navigation
		})
		this.keydownEventListener = e => this.keydownHandler(e)
		window.addEventListener('keydown', this.keydownEventListener)
	}
}
</script>

<style scoped lang="scss">
	.fileView {
		margin: -25px -30px;
		width: calc(100% + 55px);
		height: calc(100% + 50px);
		display: flex;
		flex-direction: column;
		position: relative;

		.hidePopoutButton {
			position: absolute;
			width: 50px;
			height: 40px;
			background-color: #D1D1D1;
			right: 12px;
			top: 70px;
		}

		iframe {
			background-color: #D1D1D1;
			flex: 1;
			border: none;
		}

		iframe.microsoft {
			margin: -1px;
		}

		.topBar {
			box-shadow: 0px 0px 12px 4px rgba(0,0,0,0.4);
			z-index: 1;
			display: flex;
			background-color: var(--bl-surface);
			align-items: center;

			img {
				margin: 20px;
				width: 18px;
				height: 18px;
			}

			h4 {
				flex: 1;
				font-size: 18px;
				font-family: Product sans;
				margin: 0;
				padding: 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			button {
				margin-right: 10px;
			}
		}
	}
</style>