<template>
	<div class="chatRoomContainer" @drop.prevent="dropHandler($event)" @dragover.prevent="dragOver = true" @dragleave.prevent="dragOver = false">
		<div class="dragOver" v-if="dragOver">
			<div style="position: relative; width: 45px; height: 45px;">
				<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path fill="var(--bl-on-primary)" d="M 11 40 Q 9.8 40 8.9 39.1 Q 8 38.2 8 37 V 29.85 H 11 V 37 Q 11 37 11 37 Q 11 37 11 37 H 37 Q 37 37 37 37 Q 37 37 37 37 V 29.85 H 40 V 37 Q 40 38.2 39.1 39.1 Q 38.2 40 37 40 Z H 25.5 Z"/></svg>
				<svg xmlns="http://www.w3.org/2000/svg" class="arrow" height="48" width="48"><path fill="var(--bl-on-primary)" d="M 24 32.35 L 14.35 22.7 L 16.5 20.55 L 22.5 26.55 V 8 H 25.5 V 26.55 L 31.5 20.55 L 33.65 22.7 Z"/></svg>
			</div>
			<div>Drop your files for {{ room.name }}</div>
		</div>
		<slot name="header" :room="room" :unreadMessages="unreadMessages">
			<div class="header">
				<h1>{{ room.name }}</h1>
			</div>
		</slot>
		<div class="bl-loader-line" v-if="currentRequest"></div>
		<DynamicScroller ref="scroller" :items="messages" :min-item-size="57" class="messages" @scroll="onScroll($event)">
			<template v-slot="{ item, index, active }">
				<DynamicScrollerItem :item="item" :active="active" :data-index="index">
					<div class="message" :class="{fromSender: item.from == currentUser}">
						<BlProfilePicture :showStatus="false" :id="item.from" />
						<span style="min-width: 10px;"></span>
						<div>
							<div>
								<div v-html="item.content"></div>
								<div v-if="item.files" class="filesContainer"><component :is="item.files._cn" v-bind="item.files._cp"></component></div>
								<div v-if="!item.files && item.placeholderFileCount" class="filePlaceholder">
									<div v-for="n in item.placeholderFileCount" :key="n"><div class="bl-loader-line"></div></div>
								</div>
							</div>
							<em>
								<span>{{ item.date }}</span>
								<BlProfilePicture v-for="readUser in item.reads" :key="readUser" :size="14" :showStatus="false" :id="readUser" />
							</em>
						</div>
					</div>
				</DynamicScrollerItem>
			</template>
		</DynamicScroller>
		<div class="newMessage">
			<div class="files">
				<div class="bl-chip" v-for="file in newMessageFiles" :key="file.id">
					<img v-if="file.icon" :src="'https://static.mixsuite.fr/file_icons/' + file.icon + '.svg'" />
					<div class="progressBar" v-if="file.progress || file.progress === 0" :class="{done: file.progress == 100}" :style="{width: file.progress + '%'}"></div>
					<span>{{ file.name }}</span>
					<button class="bl-icon-button" @click="removeFile(file)">close</button>
				</div>
			</div>
			<BlEmojiPicker @value="emoticon" />
			<button class="bl-icon-button" @click="uploadFiles()">file_upload</button>
			<div class="messageTextContainer">
				<div class="bl-light-scroll" contenteditable ref="newMessage" @input="newMessage = $event.target.innerHTML" v-on:keydown.enter="$event.ctrlKey ? simulateEnter() : send($event)" @focus="read()" @blur="unactiveRoom()"></div>
				<button class="bl-icon-button sendMessageButton" @click="send()" :disabled="!newMessage.length && !newMessageFiles.length">send</button>
			</div>
		</div>
	</div>
</template>

<script>
import { ViewLoader, EventEmitter, Realtime } from 'InterfaceBundle'
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { ChatManager } from 'ChatBundle'
import { UploadHelpers } from 'FileBundle'
import dayjs from 'dayjs'

export default {
	name: 'BlChatRoom',
	props: ['roomId'],
	data() {
		return {
			currentUser: ViewLoader.data.userPreferences.id,
			room: {},
			messages: [],
			newMessage: '',
			newMessageFiles: [],
			currentRequest: true,
			minId: null,
			noMoreMessages: false,
			unreadMessages: false,
			newMessageSub: null,
			readSub: null,
			readUsers: {},
			resizeObserver: null,
			dragOver: false
		}
	},
	created() {
		this.getRoom().subscribe(() => {
			this.newMessageSub = Realtime.listen('chat.message.' + this.roomId)
			for(let read of this.room.reads) this.readUsers[read.member] = read.message
			this.readSub = Realtime.listen('chat.read.' + this.roomId)
			this.readSub.then(data => {
				if(this.readUsers[data.user]) this.handleRead(this.readUsers[data.user], data.user, 'remove')
				this.readUsers[data.user] = data.message
				this.handleRead(this.readUsers[data.user], data.user, 'add')
			})
			this.newMessageSub.then(this.handleNewMessage)
			this.loadMessages().subscribe(() => {
				this.$nextTick(() => this.$refs.scroller.scrollToBottom())
				//Set first read data
				for(let read of this.room.reads) this.handleRead(read.message, read.member, 'add')
				this.markAsRead()
			})
			this.markAsRead()
		})

		this.modelChangeSub = ModelChangeEventHelpers.listen('internals.chatroom', this.roomId)
		this.modelChangeSub.subscribe(() => this.getRoom())
	},
	mounted() {
		this.resizeObserver = new ResizeObserver(this.resizeHandler)
		this.resizeObserver.observe(this.$el)
		this.$refs.newMessage.focus()
	},
	unmounted() {
		this.resizeObserver.disconnect()
		if(this.newMessageSub) this.newMessageSub.unsubscribe()
		if(this.readSub) this.readSub.unsubscribe()
		ModelChangeEventHelpers.unsubscribe(this.modelChangeSub)
	},
	methods: {
		resizeHandler() {
			this.$refs.scroller.scrollToBottom()
		},
		getRoom() {
			const req = {}
			req['context("room"):model.get("internals.chatroom").findOneById(' + this.roomId + ')'] = {
				name: null,
				userroom: null,
				members: {
					'loop("member"):local.room.members': {
						id: null,
						name: 'local.member.__toString()'
					}
				},
				reads: {
					'loop("read"):local.room.read': {
						member: 'local.read.member.id',
						message: 'local.read.message.id'
					}
				}
			}
			const ret = new EventEmitter()
			Api.post('api/structure/', req).then(resp => {
				this.room = resp
				if(!this.room.name) this.room.name = this.room.members.filter(m => m.id != this.currentUser).map(m => m.name).join('')
				ret.emit()
			})
			return ret
		},
		handleRead(messageId, userId, operation) {
			if(userId == this.currentUser) return
			let message = this.messages.filter(m => m.id == messageId)
			if(!message.length) return
			message = message[0]
			if(operation == 'add') message.reads.push(userId)
			else message.reads = message.reads.filter(r => r != userId)
		},
		onScroll(event) {
			if(event.target.scrollTop < 200 && !this.currentRequest && !this.noMoreMessages) {
				//Load more
				this.loadMessages().subscribe(() => {
					const initialScroll = event.target.scrollHeight
					this.$nextTick(() => {
						event.target.scrollTop = event.target.scrollHeight - initialScroll + event.target.scrollTop
					})
				})
			}
		},
		send(event = null) {
			if(event) {
				event.stopPropagation()
				event.preventDefault()
			}
			if(!this.newMessage.length && !this.newMessageFiles.length) return

			Api.post('form/internals.chatmessage', {submit: {
				content: this.newMessage,
				files: this.newMessageFiles.map(f => {
					const ret = {name: f.name}
					if(f.tmpName) {
						ret.tmpPath = f.tmpName
						ret.lastModified = f.lastModified
					}
					if(f.id) ret.id = f.id
					return ret
				}),
				room: this.roomId
			}})

			this.messages.push({
				from: this.currentUser,
				content: this.newMessage,
				id: 'TMP' + (new Date()).getTime(),
				date: dayjs().format('DD MM YYYY HH:mm') ,
				reads: [],
				placeholderFileCount: this.newMessageFiles.length
			})
			this.newMessage = ''
			this.newMessageFiles = []
			this.$refs.newMessage.innerHTML = ''
			setTimeout(() => this.$refs.scroller.scrollToBottom(), 250)
			return false
		},
		simulateEnter() {
			document.execCommand('insertHTML', false, '<br /><br />')
		},
		emoticon(value) {
			if(document.activeElement.activeElement != this.$refs.newMessage) {
				this.$refs.newMessage.focus()
				document.execCommand('selectAll', false, null)
				document.getSelection().collapseToEnd()
			}
			document.execCommand('insertHTML', false, value)
		},
		loadMessages() {
			if(this.noMoreMessages) return
			this.currentRequest = true
			const ret = new EventEmitter()
			const req = {
				model: 'internals.chatmessage',
				fields: [
					{name: 'from.id'},
					{name: 'date'},
					{name: 'content'},
					{name: 'id'},
					{name: 'files'}
				],
				filters: ['&', ['room.id', '=', this.roomId]],
				limit: 20,
				offset: 0,
				sort: [{field: 'id', order: 'DESC'}]
			}

			if(this.minId) req.filters.push(['id', '<', this.minId])

			Api.post('api/', {data: req}).then(resp => {
				if(!resp.data.data.length) this.noMoreMessages = true
				for(let item of resp.data.data) {
					if(!this.minId || item.id < this.minId) this.minId = item.id
					item.from = item['from.id']
					item.reads = []
					this.messages.unshift(item)
				}
				ret.emit(true)
				this.currentRequest = false
			})
			return ret
		},
		handleNewMessage(data) {
			let handled = false
			if(data.from == this.currentUser) {
				for(let item of this.messages) {
					if(typeof item.id === 'string' && item.id.substr(0, 3) == 'TMP' && item.from == data.from && item.content == data.content) {
						handled = true
						item.id = data.id
						item.files = data.files
					}
				}
			}
			else if(ChatManager.activeRoom != this.roomId) {
				this.unreadMessages = true
			}

			if(!handled) {
				data.reads = []
				this.messages.push(data)
				if(ChatManager.activeRoom == this.roomId) this.markAsRead()
				this.$nextTick(() => this.$refs.scroller.scrollToBottom())
			}
		},
		read() {
			this.unreadMessages = false
			ChatManager.focus.emit(this.roomId)
			ChatManager.activeRoom = this.roomId
			this.markAsRead()
		},
		unactiveRoom() {
			ChatManager.activeRoom = null
		},
		markAsRead() {
			if(!this.messages.length) return
			const lastMessage = this.messages[this.messages.length - 1].id
			if(typeof lastMessage === 'string') return
			const req = {}
			req['context("data"):script.run("internalsChatRoomRead", ' + lastMessage + ')'] = {}
			Api.post('api/structure/', req)
		},
		uploadFiles() {
			UploadHelpers.upload(true, null, (success, fileOption) => {
				if(success) this.newMessageFiles.push(fileOption)
			}, () => this.$forceUpdate(), () => {
				this.$refs.newMessage.focus()
				this.$forceUpdate()
			})
		},
		dropHandler(event) {
			this.dragOver = false
			UploadHelpers.dropHandler(event, true, fileOption => {
				this.newMessageFiles.push(fileOption)
			}, () => this.$forceUpdate(), () => {
				this.$refs.newMessage.focus()
				this.$forceUpdate()
			})
		},
		removeFile(file) {
			this.newMessageFiles = this.newMessageFiles.filter(f => f != file)
		}
	}
}
</script>

<style scoped lang="scss">
	.chatRoomContainer {
		height: 100%;
		background-color: var(--bl-background);
		display: flex;
		align-items: stretch;
		flex-direction: column;
		position: relative;
	}

	.messages {
		flex: 1;

		.message {
			display: flex;
			padding: 10px;

			div > div {
				background-color: var(--bl-surface);
				border-radius: var(--bl-border-radius);
				padding: 10px;

				> div {
					padding: 0;
				}

				:deep .fileView {
					height: 100px;
				}
			}

			em {
				color: var(--bl-legend);
				font-style: normal;
				font-size: 10px;
				display: block;
				display: flex;
				align-items: center;
				flex-direction: row-reverse;

				span {
					padding: 0 4px;
				}
			}

			em > div {
				margin: 1px;
			}

			.filesContainer > div {
				display: block !important;

				:deep > div {
					margin-right: 0 !important;
				}
			}

			.filePlaceholder > div {
				border-radius: var(--bl-border-radius);
				background-color: var(--bl-surface) !important;
				border: 1px solid var(--bl-border);
				width: 200px;
				height: 100px;
				margin-bottom: 4px;
				overflow: hidden;

				> div {
					margin-top: 0;
					background-color: var(--bl-surface) !important;
				}
			}
		}

		.message > div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.message.fromSender {
			flex-direction: row-reverse;

			div > div {
				background-color: var(--bl-primary);
				color: var(--bl-on-primary);
				text-align: right;
			}

			em {
				text-align: right;
				flex-direction: row;
			}
		}

		.message.fromSender > div {
			align-items: flex-end;
		}
	}

	.newMessage {
		display: flex;
		flex-wrap: wrap;
		padding: 4px;

		.messageTextContainer {
			background-color: var(--bl-surface);
			border-radius: var(--bl-border-radius);
			flex: 1;
			display: flex;

			div[contenteditable] {
				flex: 1;
				outline: none;
				padding: 10px;
				max-height: 71px;
				overflow-x: auto;
			}
		}

		.sendMessageButton:not([disabled]) {
			color: var(--bl-secondary);
		}
	}

	.header {
		display: flex;
		align-items: center;
		background-color: var(--bl-surface);
		padding: 5px;
		border-bottom: 1px solid var(--bl-border);

		h1 {
			flex: 1;
			font-family: 'Product sans';
			font-size: 18px;
			color: var(--bl-legend);
			margin: 0;
			padding: 5px;
		}
	}

	.dragOver:before {
		background-color: var(--bl-primary);
		width: 100%;
		height: 100%;
		content: ' ';
		width: 100%;
		position: absolute;
		opacity: .8;
		pointer-events: none;
	}

	.dragOver {
		width: 100%;
		backdrop-filter: blur(5px);
		position: absolute;
		display: flex;
		align-items: center;
		border-radius: var(--bl-border-radius);
		height: 100%;
		justify-content: center;
		font-weight: 500;
		color: var(--bl-on-primary);
		z-index: 4;
		font-size: 16px;
		box-shadow: 0 0 0 3px var(--bl-primary);
		pointer-events: none;

		div {
			z-index: 2;
		}

		svg {
			position: absolute;
			transform: scale(.6);
		}

		svg.arrow {
			animation: dragOverAnimateArrow 1.5s infinite cubic-bezier(0.42, 0.0, 0.58, 1.0);
		}
	}

	.files {
		display: flex;
		flex-wrap: wrap;
		min-width: 100%;
		flex: 1;

		.bl-chip {
			display: flex;
			border: 1px solid var(--bl-border);
			border-radius: var(--bl-border-radius);
			background-color: var(--bl-surface);
			align-items: center;
			position: relative;
			overflow: hidden;
			padding: 2px 5px;
			margin: 0 5px 5px 0;

			span {
				color: var(--bl-legend);
				padding: 0 2px 0 5px;
			}

			img {
				height: 15px;
			}

			button.bl-icon-button {
				padding: 0;
				font-size: 18px;
			}

			.progressBar {
				opacity: .2;
				background-color: var(--bl-primary);
				position: absolute;
				height: 100%;
				border-radius: var(--bl-border-radius);
				z-index: 0;
				transition: opacity .4s, width .1s;
				transition-timing-function: linear;
			}

			.progressBar.done {
				opacity: 0;
				cursor: default;
				pointer-events: none;
			}
		}
	}

	@keyframes dragOverAnimateArrow {
		0% {
			opacity: 1;
			transform: scale(.6) translateY(-10px);
		}

		50% {
			opacity: .4;
			transform: scale(.6) translateY(0);
		}

		100% {
			opacity: 1;
			transform: scale(.6) translateY(-10px);
		}
	}
</style>