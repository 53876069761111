<template>
	<form @submit.prevent="submit">
		<div class="bl-card" v-if="modelChanged" style="display: flex; align-items: center; color: var(--bl-warning); background-color: var(--bl-warning-bg);">
			<i class="material-icons" style="margin: 0 5px;">sync_problem</i>
			<b style="flex: 1; font-family: Product sans;">{{ $t('form.objectUpdated') }}</b>
			<blButton :label="$t('form.reloadObject')" classlist="outlined primary" @click="reloadForm()" />
		</div>
		<div class="bl-card" ref="formErrorsContainer" v-if="form.messages.length" style="display: flex; align-items: center; color: var(--bl-on-primary); background-color: var(--bl-error); white-space: pre-wrap; font-weight: 500; padding: 10px;">
			{{ form.messages.join("\n") }}
		</div>
		<BlView v-if="modelMeta" :model="modelMeta.id ? modelMeta.name : null" :modelId="modelMeta.id">
			<slot></slot>
		</BlView>
		<div style="text-align: center; margin-top: 5px;" v-if="showSubmitButton">
			<BlFormSubmit :default="true" />
		</div>
	</form>
</template>

<script>
import { Api, ModelChangeEventHelpers } from 'ModelBundle'
import { Router, Snackbar, ViewServices, EventEmitter } from 'InterfaceBundle'
import { BlForm } from './BlForm.js'
import { FormEvents } from 'FormBundle'

export default {
	name: 'BlForm',
	props: ['name', 'context', 'saveButtonLabel', 'url', 'disableDialogLeave', 'showUsersOnRoute', 'multi', 'beforeSubmit', 'submitTabIndex'],
	emits: ['modelValue', 'submitted', 'ready', 'additionalData'],
	data() {
		return {
			form: new BlForm('form', '_root'),
			currentRequest: true,
			modelMeta: null,
			modelChanged: false,
			submitted: false,
			liveUpdateFieldsCache: {},
			liveUpdateDebounce: null,
			disableSaveShortcut: false,
			formReady: new EventEmitter(),
			formLiveReload: new EventEmitter(),
			afterRequest: new EventEmitter(),
			additionalData: null,
			formSubmitChangeDetector: new EventEmitter(),
			showSubmitButton: true
		}
	},
	methods: {
		/**
		 * Get API url
		 * @return {String}
		 */
		getUrl() {
			if(this.url) return this.url
			let ret = this.name
			if(this.multi) {
				if(ViewServices.routeParams.ids && !FormEvents.disableRouting) ret += '/' + ViewServices.routeParams.ids
				return 'multi/' + ret
			}
			if(ViewServices.routeParams.id && !FormEvents.disableRouting) ret += '/' + ViewServices.routeParams.id
			return ret
		},

		/**
		 * Reload form
		 */
		reloadForm() {
			Router.reload()
		},

		/**
		 * Handle field live update
		 * @param  {object} fields
		 * @return Promise
		 */
		liveUpdateFields(fields) {
			return new Promise((resolve, reject) => {
				for(let key in fields) {
					if(!fields[key].loadAll && this.form.getChildByQualifiedName(key).options.loadAll) fields[key].loadAll = true
					this.liveUpdateFieldsCache[key] = fields[key]
				}
				if(this.liveUpdateDebounce) clearTimeout(this.liveUpdateDebounce)
				this.liveUpdateDebounce = setTimeout(() => this.doLiveUpdateFields(resolve, reject), 10)
			})
		},
		/**
		 * Execute live update request
		 * @return {Promise}
		 */
		doLiveUpdateFields(res, rej) {
			this.liveUpdateDebounce = null
			let fields = this.liveUpdateFieldsCache
			this.liveUpdateFieldsCache = {}
			return new Promise((resolve, reject) => {
				this.getPromise({fieldLoader: fields, submit: this.form.data}).then(resp => {
					for(let field of Object.keys(fields)) {
						if(!resp[field]) continue
						let child = this.form.getChildByQualifiedName(field)
						let rebuildChildren = false

						//Dynamic form management, rebuild form if name has changed
						if((child.options.form || resp[field].options.form) && resp[field].options.form != child.options.form) rebuildChildren = true

						child.updateOptions(resp[field].options)
						if(rebuildChildren) {
							child.value = []
							child.children = []
							this.buildFormChildren(resp[field], child)
						}
						else if(child.options.autoFill) {
							child.value = []
							this.buildFormChildren(resp[field], child)
						}
						child.label = resp[field].label
						if(child.options.override_value !== null) child.setValue(child.options.override_value)
						child.initialize()
					}
					res()
					resolve()
					this.formLiveReload.emit()
				}).catch(() => {
					rej()
					reject()
				})
			})
		},
		/**
		 * Recursive form build from backend metadata
		 * @param  {BlForm} parent
		 * @param  {object} fieldDatum
		 */
		buildForm(parent, fieldDatum) {
			for(let fieldData of Object.values(fieldDatum)) {
				let child = new BlForm(fieldData.type, fieldData.name, fieldData.label, fieldData.options, fieldData.validation, this, fieldData.value)
				this.buildFormChildren(fieldData, child)
				parent.addChild(child)
			}
		},
		/**
		 * Build form children (separated for reusability in liveupdate)
		 * @param  {object} fieldData
		 * @param  {object} parent
		 */
		buildFormChildren(fieldData, parent) {
			if(fieldData.children) {
				if(fieldData.type == 'collection') {
					parent.value = []
					for(let id of fieldData.childrenOrder) {
						let subChild = new BlForm(fieldData.type, fieldData.name, fieldData.label, fieldData.options, fieldData.validation, this, fieldData.value)
						subChild.key = id + ''
						subChild.parent = parent
						this.buildForm(subChild, fieldData.children[id])
						parent.value.push(subChild)
					}
				}
				else if(fieldData.type == 'oneToOne') {
					let subChild = new BlForm(fieldData.type, fieldData.name, fieldData.label, fieldData.options, fieldData.validation, this, fieldData.value)
					subChild.parent = parent
					this.buildForm(subChild, fieldData.children)
					parent.value = subChild
					parent.children = subChild.children
				}
			}
		},
		/**
		 * Preprocess form submission
		 */
		submit() {
			document.activeElement.blur()
			if(this.beforeSubmit) {
				if(this.beforeSubmit() === true) this.doSubmit()
			}
			else this.doSubmit()
		},
		/**
		 * Process form submission
		 */
		async doSubmit() {
			this.form.messages = []
			this.$nextTick(async () => {
				this.form.emitter.beforeValidation.emit()
				if(this.form.isValid()) {
					for(let field of this.form.flatten()) field.messages = []
					this.currentRequest = true
					this.submitted = true
					this.form.emitter.beforeSubmit.emit()
					const emitter = {events: []}
					this.form.onBeforeSubmit.emit(emitter)
					const promises = emitter.events.map(ev => new Promise((resolve) => ev.once(() => resolve())))
					await Promise.all(promises)
					this.getPromise({submit: this.form.data}).then(resp => {
						this.form.messages = []
						if(this.disableDialogLeave !== true) Router.allowLeave()
						this.currentRequest = false
						if(resp.__route && !FormEvents.disableRouting) Router.navigate(resp.__route, !this.getUrl().includes('/'))
						if(resp.__toast) Snackbar.open({text: resp.__toast})
						FormEvents.submitted.emit(resp)
						this.$emit('submitted', resp)
						this.afterRequest.emit({valid: true, response: resp})
					}).catch(error => {
						this.currentRequest = false
						if(error.error) this.form.bindMessages(error.error)
						if(error.globalErrors) {
							this.form.messages = error.globalErrors
							this.$nextTick(() => this.$refs.formErrorsContainer.scrollIntoView())
						}
						if(!error.error && !error.globalErrors) {
							this.form.messages = [this.$t('form.unknownError')]
							this.$nextTick(() => this.$refs.formErrorsContainer.scrollIntoView())
						}
						this.afterRequest.emit({valid: false, response: error})
					})
				}
				else {
					//Focus first errored field and set all fields as touched
					let errorFocused = false
					for(let field of this.form.flatten()) {
						if(!errorFocused && !field.isValid() && !['form', 'collection', 'oneToOne'].includes(field.type)) {
							field.emitter.focus.emit()
							errorFocused = true
						}
						field.setTouched()
					}
				}
			})
		},
		/**
		 * Focus errored field
		 * @param {BlForm} form
		 */
		focusErroredField(form) {
			for(let item of form.children) {
				if(!item.isValid()) {
					item.emitter.focus.emit()
					return
				}
				else this.focusErroredField(item)
			}
		},
		/**
		 * Get form promise
		 * @param  {object} form data
		 * @return {object}
		 */
		getPromise(data = null) {
			let url = this.getUrl()
			if(!data) data = {}
			data.context = { ...this.context }
			Api._formatParameters(data.context)
			data.context = JSON.stringify(data.context)
			return url.includes('/') ? Api.put('form/' + url, data) : Api.post('form/' + url, data)
		},
		/**
		 * Handle keyboard shortcuts
		 * @param  {object} event
		 */
		handleKeyboardShortcuts(event) {
			if(event.key == 's' && event.ctrlKey && !this.currentRequest && !Router.unsavedDataDialogOpened && !this.disableSaveShortcut) {
				event.preventDefault()
				event.stopPropagation()
				this.submit()
				return false
			}
		},

		/**
		 * Load form data from backend
		 */
		loadForm() {
			let ret = new EventEmitter()
			this.getPromise().then(resp => {
				this.buildForm(this.form, resp.fields)
				this.modelMeta = resp.model
				this.$.provides.blFormReady.emit()
				this.$.provides.blFormReady = true
				this.$emit('modelValue', this.form.data)
				this.form.emitter.change.subscribe(() => this.$emit('modelValue', this.form.data))
				this.currentRequest = false
				FormEvents.ready.emit(this)
				this.$emit('ready')
				if(resp.additionalData) {
					this.additionalData = resp.additionalData
					this.$emit('additionalData', this.additionalData)
				}
				ret.emit()
			}, err => {
				if(err && err.status == 404) Router.notFound()
			})
			return ret
		},

		/**
		 * Get structure binding
		 * @return {object}
		 */
		getStructureBinding() {
			//Trigger beforesubmit to handle positions
			this.form.emitter.beforeSubmit.emit()
			let url = this.getUrl()
			return {
				name: this.name,
				id: url.includes('/') ? parseInt(url.split('/')[1]) : null,
				context: this.context,
				data: this.form.data
			}
		},
		formatSubmitDatum() {
			return {
				saveButtonLabel: this.saveButtonLabel,
				currentRequest: this.currentRequest,
				submitTabIndex: this.submitTabIndex
			}
		}
	},
	watch: {
		'form.changed'() {
			if(this.disableDialogLeave !== true) Router.preventLeave()
		},
		saveButtonLabel() {
			this.formSubmitChangeDetector.emit(this.formatSubmitDatum())
		},
		currentRequest() {
			this.formSubmitChangeDetector.emit(this.formatSubmitDatum())
		},
		submitTabIndex() {
			this.formSubmitChangeDetector.emit(this.formatSubmitDatum())
		}
	},
	created() {
		this.form.onBeforeSubmit = new EventEmitter()
		FormEvents.activeForms.push(this.name)
		FormEvents.activeFormChange.emit()
		Router.showUsersOnRoute(this.showUsersOnRoute !== false)
		//Fetch data from backend
		this.loadForm().subscribe(() => {
			if(this.getUrl().includes('/') && !this.multi) {
				this.rt = ModelChangeEventHelpers.listen(this.modelMeta.name, parseInt(this.getUrl().split('/')[1]))
				this.rt.subscribe(() => {
					if(!this.submitted) this.modelChanged = true
				})
			}
		})
		document.addEventListener('keydown', this.handleKeyboardShortcuts)
	},
	unmounted() {
		for(let field of this.form.flatten()) field.destroy()
		FormEvents.activeForms = FormEvents.activeForms.filter(f => f != this.name)
		FormEvents.activeFormChange.emit()
		Router.showUsersOnRoute(false)
		if(this.disableDialogLeave !== true) Router.allowLeave()
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
		if(this.rt) ModelChangeEventHelpers.unsubscribe(this.rt)
	},
	provide() {
		return {
			blForm: this.form,
			blFormReady: this.formReady,
			blFormLiveReloaded: this.formLiveReload,
			blFormLiveUpdateFields: fields => this.liveUpdateFields(fields),
			blFormSubmitChangeDetector: this.formSubmitChangeDetector,
			blFormSubmitShowDefault: value => {
				if(value !== null) this.showSubmitButton = value
				this.formSubmitChangeDetector.emit(this.formatSubmitDatum())
			}
		}
	}
}
</script>

<style scoped lang="scss">
</style>